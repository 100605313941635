<template>
  <div class="preview-survey">
    <template v-if="!error && survey">
      <template v-if="!isFinished">
        <div class="survey-progress">
          <b-progress :value="current" :max="max" size="is-small" show-value type="is-dark-green">
            <span v-if="max">{{ current }} / {{ max }}</span>
          </b-progress>
        </div>
        <section class="section">
          <ValidationObserver tag="div" class="container" v-slot="{ handleSubmit, invalid }">
            <form id="survey" @submit.prevent="handleSubmit(onSubmit)">
              <template v-if="page">
                <div v-for="question in questions" :key="question.qid" class="mb-4">
                  <div class="columns is-centered is-vcentered">
                    <component
                        :is="`Q${question.type}`"
                        :question="question"
                        :lang="lang"
                        :value="changes"
                        :loading="loading"
                        :show-question-code="showQuestionCode"
                        @input="onChange"
                    ></component>
                  </div>
                </div>
              </template>
              <FormNav
                  class="form-nav my-5 has-text-centered"
                  @back="onBack"
                  :backable="current > 0"
                  :nextable="!(current === max) || invalid"
                  :hide-back-btn="!survey.is_backable"
                  :back-text="$t('btn.previous')"
                  :next-text="$t(`btn.${isLastPage ? 'submit' : 'next'}`)"
                  :loading="loading"
              ></FormNav>
            </form>
          </ValidationObserver>
        </section>
      </template>
      <section v-if="isFinished" class="hero is-fullheight">
        <div class="hero-body">
          <div class="container">
            <div class="columns is-centered is-vcentered">
              <div class="column is-8">
                <div v-if="!endType" class="has-text-centered">
                  <h1 class="title">
                    {{ $t('endMessage') }}
                  </h1>
                </div>
                <SandboxHtml v-if="endType === 'message'" :html="translations.end_msg"></SandboxHtml>
              </div>
            </div>
          </div>
        </div>
      </section>
    </template>
    <ErrorMessage :error="error"></ErrorMessage>
  </div>
</template>

<script>
import FormNav from '@/components/FormNav'

import { getSurveyFull } from '@/api/response'
import { QuestionTypeComponentsMixin } from '@/mixins/question-type-components'
import { SurveyMetaMixin } from '@/mixins/survey-meta'
import { checkConditions } from '@/utils/question'
import { LocaleSwitchingMixin } from '@/mixins/locale-switching'
import { SurveyEndMixin } from '@/mixins/survey-end'
import { ScrollToTopMixin } from '@/mixins/scroll-to-top'
import { ErrorHandlerMixin } from '@/mixins/error-handler'
import { PreviewMixin } from '@/mixins/preview'

export default {
  name: 'PreviewSurvey',
  mixins: [
    QuestionTypeComponentsMixin,
    SurveyMetaMixin,
    LocaleSwitchingMixin,
    SurveyEndMixin,
    ScrollToTopMixin,
    ErrorHandlerMixin,
    PreviewMixin,
  ],
  components: {
    FormNav,
  },
  data () {
    return {
      survey: null,
      loading: false,
      isFinished: false,
      current: 0,
    }
  },
  computed: {
    page () {
      return this.survey?.pages[this.current]
    },
    questions () {
      return this.survey?.questions
          .filter(question => question.pid === this.page?.pid)
          .filter(question => checkConditions(question?.conditions, this.changes))
    }
  },
  watch: {
    '$route.query.page_code' (pageCode) {
      this.selectPageByCode(pageCode)
    },
    '$route.query.page_id' (pageId) {
      this.selectPageById(pageId)
    },
    '$route.query.page_index' (pageIndex) {
      this.current = +pageIndex
    },
  },
  methods: {
    async init () {
      this.error = null
      const loadingComponent = this.$buefy.loading.open()
      try {
        const { params: { code } } = this.$route
        const { data: { data } } = await getSurveyFull(code)
        this.survey = data

        const lang = this.$route.query?.lang
        if (lang && !data?.supported_languages.includes(lang)) {
          this.error = {
            code: 400,
            message: this.$t('errors.LANGUAGE_NOT_SUPPORTED')
          }
        }

        const { page_code, page_id, page_index } = this.$route.query
        if (page_code) this.selectPageByCode(page_code)
        else if (page_id) this.selectPageById(page_id)
        else if (page_index) this.current = +page_index
      } catch (error) {
        this.errorHandler(error)
      } finally {
        loadingComponent.close()
      }
    },
    async onSubmit () {
      this.loading = true
      this.current++

      if (this.questionLang && this.lang !== this.questionLang) {
        await this.$router.replace({ ...this.$route.query, lang: this.questionLang })
      }

      if (this.current >= this.max) this.isFinished = true

      // Handle empty page
      if (this.page && !this.questions?.length) await this.onSubmit()

      this.loading = false
      this.scrollToTop()
    },
    selectPageByCode (pageCode) {
      const pageIndex = this.survey?.pages?.findIndex(page => page.code === pageCode)
      if (pageIndex > -1) this.current = pageIndex
    },
    selectPageById (pageId) {
      const pageIndex = this.survey?.pages?.findIndex(page => page.pid === pageId)
      if (pageIndex > -1) this.current = pageIndex
    },
    async onBack () {
      this.loading = true
      if (this.page) this.current--
      this.loading = false

      if (!this.questions?.length) await this.onBack()
    },
  },
  created () {
    this.init()
  }
}
</script>
